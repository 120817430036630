import axios from 'axios';
import React from 'react';

import useScript from './../../hooks/useScript';

const MercadoPagoButton = (props) => {
  let paymentId =
    props.plan === 'clase'
      ? '159728357-57f4c55d-c042-47d6-8c46-776c35f5a0df'
      : '159728357-b9c84e4e-0462-41eb-9740-4a7929176dbb';

  useScript(
    'https://www.mercadopago.com.ar/integrations/v1/web-payment-checkout.js',
    'mlButton',
    paymentId,
  );

  const handleAutoSave = () => {
    axios({
      method: 'POST',
      url: 'https://ajedrezlatino.com/curso-febrero/api/auto_save.php',
      data: JSON.stringify(props.data),
    });
  };

  return (
    <div
      id="mlButton"
      style={{ display: 'none' }}
      onClick={handleAutoSave}
    ></div>
  );
};

export default MercadoPagoButton;
