import React from 'react';
import FlipCountdown from '@rumess/react-flip-countdown';

const AboutCourse = ({
  limitDateOfDiscount,
  isDiscountAvailable,
  dateToShow,
  titleOfCourse,
  hoursOfClasses,
  classDuration,
}) => {
  const limitDate = new Date(limitDateOfDiscount + ' 12:00:00');
  const formmatedLiminDateOfDiscount = `${limitDate.getDate()}/${
    String(limitDate.getMonth() + 1).length === 1
      ? `0${limitDate.getMonth() + 1}`
      : limitDate.getMonth() + 1
  }`;

  return (
    <section className="about row">
      <div className="col-12 heading">
        <h2>Información del curso:</h2>
      </div>

      <div className="col-lg-6 sm-12">
        <h3>{titleOfCourse}</h3>
        <p className="about-description">
          Del {dateToShow}
          <br />
          <br />
          -10% OFF Hasta el {formmatedLiminDateOfDiscount} a las 23:59hs
          <br />
          <br />
          -Duración de las clases {classDuration}.
          <br />
          <br />
          -Serán {hoursOfClasses} de clases del {dateToShow}.
          <br />
          que quedarán grabadas y a disposición de los alumnos.
          <br />
          <br />
        </p>
      </div>
      <div className="col-lg-6 sm-12 vertical-align">
        {isDiscountAvailable ? (
          <div
            className="clock-countdown"
            style={{ backgroundColor: '#c7c5c5' }}
          >
            <h1 className="Montserrat" style={{ color: '#f02626' }}>
              10% OFF se acaba en:
            </h1>
            <FlipCountdown
              theme="light"
              hideYear
              hideMonth
              endAt={`${limitDateOfDiscount} 23:59:59`} // Date/Time
            />
          </div>
        ) : (
          <h1
            style={{
              textAlign: 'center',
              fontSize: '2.2em',
            }}
          >
            Se ha acabado el descuento
          </h1>
        )}
      </div>

      <hr className="hr" />
    </section>
  );
};

export default AboutCourse;
