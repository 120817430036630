import React from "react";
import { reedirect } from "../../helper/reedirect";

const Youtube = () => {
  return (
    <div className="container-fluid youtube">
      <div className="container">
        <div className="row">
          <div style={{ textAlign: "center" }} className="col-lg-2 sm-6 va">
            <h1>2022</h1>
            <h4>CANAL DE YOUTUBE</h4>
            <button
              onClick={() => reedirect("https://youtube.com/ajedrezlatino")}
              className="btn btn-blue"
            >
              Ir al canal
            </button>
          </div>
          <div className="col-lg-4 sm-6 va">
            <h3>Apoyanos suscribiendote al canal!</h3>
            <h6>
              {" "}
              Ya puedes disfrutar de algunos vídeos, y a lo largo de los
              próximos meses, nuestro canal de Youtube será uno de los ejes
              sobre los que articularemos gran parte de nuestro contenido. En él
              encontrarás videos educativos, vivos de grandes torneos y otros
              eventos con nuestros maestros.
            </h6>
          </div>
          <div className="col-lg-6 sm-12">
            <img
              src="/images/youtube.jpg"
              alt="youtube ajedrezlatino channel"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Youtube;
