import React from 'react';

const Profesors = () => {
  return (
    <section className="profesors row">
      <div className="heading col-12">
        <h2>Maestros del curso:</h2>
      </div>
      <article className="profesor col-lg md sm-12">
        <img
          className="profesor-photo"
          src="/images/profesors/gmandresrodriguez.jpeg"
          alt="GM Andres Rodriguez"
        />
        <h4 className="profesor-name">GM Andrés Rodríguez</h4>
        <p className="profesor-description">
          Gran Maestro Internacional de ajedrez desde 1997. Campeón Panamericano
          Juvenil en Asunción del Paraguay en mayo de 1990. Medalla de plata en
          el primer tablero en la 36° Olimpíada de ajedrez realizada en Calviá
          (España), en 2004. Entrenador de varios campeones mundiales como Deysi
          Cori, Jorge Cori y Alan Pichot. Co-fundador y director de Ajedrez
          Latino. Entrenador en el Plan de Alto Rendimiento de FENAMAC (México).
        </p>
      </article>

      <article className="profesor col-lg md sm-12">
        <img
          className="profesor-photo"
          src="/images/profesors/mijorgerosito.jpeg"
          alt="MI Jorge Rosito"
        />
        <h4 className="profesor-name">MI Jorge Rosito</h4>
        <p className="profesor-description">
          Maestro Internacional desde 1998 Norma de GM en 2003 Entrenador de GM
          Diego Flores, GM García Palermo, MI Marcelo Tempone Entrenador de
          varios juveniles campeones nacionales Bicampeón argentino y
          panamericano sub 16 Mejor entrenador de Argentina en 2016 (Premios
          Fada) Co-fundador y director de Ajedrez Latino. Entrenador en el Plan
          de Alto Rendimiento de FENAMAC (México).
        </p>
      </article>

      {/*       <article className="profesor col-lg md sm-12">
        <img className="profesor-photo" src="/images/profesors/frois1.jpeg" />
        <h4 className="profesor-name">MI António Fróis</h4>
        <p className="profesor-description">
          Campeón Europeo Veteranos 2014 en Ritmo Blitz Experiencia de enseñanza
          con todos niveles desde 1984 Jugó 6 Olimpíadas por Portugal, Capitán
          en 2016 (Bakú) Co-Autor de varios libros de ajedrez Manuales de La
          formación de la Federación Portuguesa de Ajedrez “ Me encanta
          fortalecer el carácter y la mentalidad de mis alumnos "
        </p>
      </article> */}

      <article className="profesor col-lg md sm-12">
        <img
          className="profesor-photo"
          src="/images/profesors/gmdiegoflores.jpeg"
          alt="GM Diego Flores"
        />
        <h4 className="profesor-name">GM Diego Flores</h4>
        <p className="profesor-description">
          Gran Maestro Internacional desde 2008. Campeón Argentino Absoluto en 7
          ocasiones. Representante Olímpico Argentino desde 2006 hasta la
          actualidad. Profesor de la Escuela Municipal de Ajedrez de Junín
          (2003-2008). Instructor en la Escuela de Talentos de la ULP San Luis
          (2013-2018). Co-fundador y director de Ajedrez Latino. Entrenador en
          el Plan de Alto Rendimiento de FENAMAC (México).
        </p>
      </article>
      {/* 
      <article className="profesor col-lg md sm-12">
        <img
          className="profesor-photo"
          src="/images/profesors/mibenjaminmela.jpeg"
          alt="MI Benjami Mela"
        />
        <h4 className="profesor-name">MI Benjamín Mela</h4>
        <p className="profesor-description">
          Maestro Internacional desde 2006 (Barcelona, España 🇪🇸) Entrenador de
          los seleccionados Catalanes de categorías (2005/2006) en las finales
          del Campeonato de España 🇪🇸 Profesor del Plan de Ajedrez escolar
          (caba) 2007 hasta la actualidad Jefe de Prensa del equipo Olimpico
          Argentino 🇦🇷 en la Olimpiada de Ajedrez Batumi 2018, Georgia 🇬🇪
          Entrenador de las Escuelas Deportivas de Fada (2014 - 2019)
          Colaborador de Chess24.
        </p>
      </article> */}

      {/*       <article className="profesor col-lg md sm-12">
        <img
          className="profesor-photo"
          src="/images/profesors/daniel-rivera.jpg"
        />
        <h4 className="profesor-name">MI Daniel Rivera Kuzawka</h4>
        <p className="profesor-description">
          Maestro Internacional de ajedrez desde 1992 FIDE Trainer Campeón
          <br />
          Nacional 1982-1985 Entrenador del equipo olímpico para ciegos de
          <br />
          España 2008 Entrenador y capitán de la selección olímpica de Uruguay
          <br />
          2010 – 2012 Entrenador y capitán de la selección olímpica de Uruguay
          <br />
          femenino 2014 - 2016 - 2018
        </p>
      </article> */}

      {/*             <hr className="hr" />
            <div className="col-12">
          <img className="profesor-photo" src="/images/profesors/mibenjaminmela.jpeg" />
                <h4 className="profesor-name">Lic Santiago Mellano</h4>
                <p className="profesor-description">Psicólogo especialista invitado
                 Entrenador de los seleccionados Catalanes de categorías (2005/2006) en las finales del Campeonato de España 🇪🇸
                Profesor del Plan de Ajedrez escolar (caba) 2007 hasta la actualidad
                Jefe de Prensa del equipo Olimpico Argentino 🇦🇷 en la Olimpiada de Ajedrez Batumi 2018, Georgia 🇬🇪
                Entrenador de las Escuelas Deportivas de Fada (2014 - 2019)
                Colaborador de Chess24.</p> </p>
            </div> */}
      <hr className="hr" />
    </section>
  );
};

export default Profesors;
