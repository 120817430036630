import React from 'react';

import Header from './../../components/Header';
import AboutUs from './../../components/AboutUs';
import Youtube from './../../components/Youtube';
/* import Peones from "./../../components/Peones"; */
import GruposDeEstudio from './../../components/GruposDeEstudio';
import Team from './../../components/Team';

const Index = () => {
  return (
    <div style={{ backgroundColor: 'rgb(5, 27, 49)' }}>
      <Header />
      <AboutUs />
      <Youtube />
      {/* <Peones /> */}
      <GruposDeEstudio />
      <Team />
    </div>
  );
};

export default Index;
