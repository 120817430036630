import React from "react";
import { useForm } from "react-hook-form";
import { updateLive } from './../../lib/api';

const AdminLite = () => {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data, event) => updateLive(data, event);

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-4 sm-12"></div>
        <div className="col-lg-4 sm-12 form">
          <form onSubmit={handleSubmit(onSubmit)}>
            <label htmlFor="title">Título</label>
            <input
              type="text"
              name="title"
              id="title"
              ref={register({ required: true })}
            />
            <small className="error">
              {errors.title && "Título es requerido"}
            </small>
            <label htmlFor="description">Descripción</label>
            <textarea
              type="text"
              name="description"
              id="description"
              ref={register({ required: true })}
            ></textarea>
            <small className="error">
              {errors.description && "Descripción es requerido"}
            </small>
            <label htmlFor="url">
              Enlance{" "}
              <small style={{fontWeight:'bolder'}}>
                (debe tener el formato https://youtu.be/nkSfL2WNKj8 )
              </small>
            </label>
            <input
              type="text"
              placeholder="https://youtu.be/nkSfL2WNKj8"
              name="url"
              id="url"
              ref={register({ required: true })}
            />
            <small className="error">
              {errors.url && "Enlance es requerido"}
            </small>
            <label htmlFor="password">Contraseña</label>
            <input
              type="password"
              name="password"
              id="password"
              ref={register({ required: true })}
            />
            <small className="error">
              {errors.password && "Contraseña es requerido"}
            </small>
            <button className="btn btn-blue btn-block">Actualizar</button>
          </form>
        </div>
        <div className="col-lg-4 sm-12"></div>
      </div>
    </div>
  );
};

export default AdminLite;
