import React from "react";

const Team = () => {
  return (
    <div className="container-fluid team">
      <div style={{ height: "70px" }}></div>
      <div className="container">
        <div className="row">
          <div
            className="col-lg-4 sm-12"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          >
            <h4>- Equipo</h4>
            <h2>Nuestro equipazo</h2>
            <p>
              Renombrados especialistas y apasionados, trabajando por hacer
              crecer el ajedrez cada día.
            </p>
          </div>
          <div className="col-lg-4 sm-12 card">
            <img src="/images/jorge-rosito-team.jfif" alt="MI Jorge Rosito" />
            <h4>MI Jorge Rosito</h4>
            <p>Profesor de Ajedrez</p>
          </div>
          <div className="col-lg-4 sm-12 card">
            <img src="/images/diego-flores-team.jfif" alt="GM Diego Flores" />
            <h4>GM Diego Flores</h4>
            <p>Profesor de Ajedrez</p>
          </div>
          <div className="col-lg-4 sm-12 card">
            <img
              src="/images/andres-rodriguez-team.jfif"
              alt="GM Andrés Rodríguez"
            />
            <h4>GM Andrés Rodríguez</h4>
            <p>Profesor de Ajedrez</p>
          </div>
          <div className="col-lg-4 sm-12 card">
            <img src="/images/benja-mela-team.png" alt="MI Benjamín Mela" />
            <h4>MI Benjamín Mela</h4>
            <p>Profesor de Ajedrez</p>
          </div>
          <div className="col-lg-4 sm-12 card">
            <img
              src="/images/robert-hungasky-team.jpg"
              alt="GM Robert Hungaski"
            />
            <h4>GM Robert Hungaski</h4>
            <p>Colaborador</p>
          </div>
        </div>
      </div>
      <div style={{ height: "50px" }}></div>
    </div>
  );
};

export default Team;
