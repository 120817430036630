import { useEffect, useState, useRef } from 'react';

export const AdminClases = () => {
  const [clases, setClases] = useState([]);

  const passwordInputRef = useRef(null);

  useEffect(() => {
    fetch('https://course-dashboard.onrender.com/clases')
      .then((res) => res.json())
      .then(({ response }) => setClases(response))
      .catch(() => {});
  }, []);

  const isClases = clases.length > 0;

  const handleCreteNewClass = () =>
    setClases([
      ...clases,
      {
        name_of_class: '',
        idx: clases.length,
        id: clases.length,
        id_curso: 1,
      },
    ]);

  const handleOnRemoveLast = () => {
    try {
      const newClasses = [...clases];
      newClasses.pop();
      setClases(newClasses);
    } catch (error) {
      console.error('No se puede remover la última clase');
    }
  };

  const handleOnInput = (e) => {
    try {
      const newClasses = [...clases];
      newClasses[e.target.id].name_of_class = e.target.value;

      setClases(newClasses);
    } catch (error) {
      console.error('Error al actualizar clase');
    }
  };

  const handleUpdate = async () => {
    const classes = clases.map(({ name_of_class }) => name_of_class);

    const body = { classes };

    if (passwordInputRef?.current?.value !== 'Ajedrez123!') {
      alert('Contraseña incorrecta');
      return;
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    };

    fetch('https://course-dashboard.onrender.com/clases', requestOptions)
      .then((res) => res.json())
      .then(({ response }) => {
        setClases(response);
        passwordInputRef['current'].value = '';
        alert('Clases actualizadas correctamente');
      });
  };

  const hasActionButtons = (idx) => idx === clases.length - 1;

  return (
    <div className="row">
      <div className="col-12 center">
        <h3>Administrador de clases</h3>
      </div>
      <div className="col-4"></div>
      <div className="col-4">
        <label htmlFor="password">Contraseña para actualizar</label>
        <br />
        <input
          type="password"
          ref={passwordInputRef}
          name="password"
          id="password"
        />
      </div>
      <div className="col-4"></div>
      <div className="col-4"></div>
      <div className="col-4 mb-5">
        {isClases &&
          clases.map(({ name_of_class, idx }) => (
            <div key={idx}>
              <input
                key={idx}
                value={name_of_class}
                id={idx}
                onInput={handleOnInput}
              />
              {hasActionButtons(idx) && (
                <>
                  <button onClick={handleCreteNewClass}>+</button>
                  {idx !== 0 && <button onClick={handleOnRemoveLast}>-</button>}
                </>
              )}
              <br />
            </div>
          ))}
      </div>
      <div className="col-4"></div>
      <div className="col-12 center">
        <button onClick={handleUpdate} className="btn btn-blue">
          Actualizar
        </button>
      </div>
    </div>
  );
};
