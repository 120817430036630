import React from "react";
import Calendar from "@ericz1803/react-google-calendar";

const API_KEY = "AIzaSyAxylh1ss_BXnEPMq3F2s6by7W5O39ZfyI";
let calendars = [
  {
    calendarId: "nkmdjnoa00mog7rci3nj51q0b8@group.calendar.google.com",
    color: "#007bff",
  },
];

const styles = {
  calendar: {
    padding: "4%",
  },
};

const language = "ES";

const Calendario = () => (
  <Calendar
    apiKey={API_KEY}
    language={language}
    calendars={calendars}
    showFooter={false}
    styles={styles}
  />
);

export default Calendario;
