import { useEffect, useState } from 'react';

export function useFetch(initialUrl) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState(initialUrl);
  const [reload, setReload] = useState(0);

  async function fetchUrl() {
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch(() => console.error('asd'));
  }

  useEffect(() => {
    fetchUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, url]);

  return [data, loading, setUrl, reload, setReload];
}
