import React from 'react';

const AboutUs = () => {
  return (
    <div className="container-fluid about-us">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 sm-12">
            <h4>- Acerca de nosotros</h4>
            <h2> Un grupo de amigos</h2>
          </div>
          <div className="col-lg-6 sm-12">
            <h6>
              Ajedrez Latino nace el 13 de abril de 2019, los maestros MI Jorge
              Rosito, GM Diego Flores y el GM Andrés Rodríguez se unen con una
              visión común potenciar el ajedrez latinoamericano.
            </h6>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4 sm-12 card">
            <img src="/images/about1.jpg" alt="acerca de nosotros 1" />
            <div className="card-description">
              <h5>SERVICIOS</h5>
              <h3>Cursos de Ajedrez</h3>
            </div>
          </div>
          <div className="col-lg-4 sm-12 card">
            <img src="/images/about2.jpg" alt="acerca de nosotros 1" />
            <div className="card-description">
              <h5>SERVICIOS</h5>
              <h3>Ajedrez Latino a la carta</h3>
            </div>
          </div>
          <div className="col-lg-4 sm-12 card">
            <img src="/images/about3.jpg" alt="acerca de nosotros 1" />
            <div className="card-description">
              <h5>SERVICIOS</h5>
              <h3>Personalizados</h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 pasion">
            <blockquote>
              En Ajedrez Latino, sentimos pasión por el ajedrez
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
