import { useState, useEffect } from 'react';

import AboutCourse from '../../components/AboutCourse';
import Profesors from '../../components/Profesors';
import Form from '../../components/Form';
import Pricing from '../../components/Pricing';

const Curso = () => {
  const [infoCurso, setInfoCurso] = useState({});
  const [clases, setClases] = useState([]);
  const [isLoadingInfo, setIsLoadingInfo] = useState(false);

  useEffect(() => {
    const getInfoCourse = async () => {
      setIsLoadingInfo(true);
      try {
        const res = await fetch(
          'https://course-dashboard.onrender.com/curso',
        ).then((response) => response.json());

        setInfoCurso(res?.response);

        const resClases = await fetch(
          'https://course-dashboard.onrender.com/clases',
        ).then((response) => response.json());

        setClases(
          resClases?.response.map(({ name_of_class }) => name_of_class),
        );
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingInfo(false);
      }
    };

    getInfoCourse();
  }, []);

  // course configuration
  const limitDateOfDiscount = infoCurso?.limit_date_of_discount || '2022-09-14';
  const isDiscountAvailable =
    new Date().toISOString().split('T')[0] <= limitDateOfDiscount;

  const pricesCompleteCourse = {
    withOutDiscount: { usd: infoCurso?.price_usd, arg: infoCurso?.price_arg },
    withDiscount: {
      usd: infoCurso?.price_usd_discount,
      arg: infoCurso?.price_arg_discount,
    },
  };

  const priceClass = {
    usd: infoCurso?.price_usd_class || 10,
    arg: infoCurso?.price_arg_class || 1400,
  };

  const listOfClasses = clases || [];

  const titleOfCourse = infoCurso?.title_of_course || 'Curso Magistral';

  const dateToShow =
    infoCurso?.date_to_show || '16 al 25 de Septiembre de 2022';
  const hoursOfClasses = infoCurso?.hours_of_classes || '9hs';
  const classDuration = infoCurso?.class_duration || '1.30 hs';
  const cronogramaURL = infoCurso?.calendar_img_url || '';

  if (isLoadingInfo) {
    return (
      <div className="container">
        <div className="row">
          <div
            className="col-12"
            style={{ textAlign: 'center', color: '#007bff' }}
          >
            <h1>Cargando...</h1>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <AboutCourse
            limitDateOfDiscount={limitDateOfDiscount}
            isDiscountAvailable={isDiscountAvailable}
            titleOfCourse={titleOfCourse}
            dateToShow={dateToShow}
            hoursOfClasses={hoursOfClasses}
            classDuration={classDuration}
          />
          <Profesors />
        </div>
        <div className="col-md-6 sm-12">
          <div className="col-12 heading">
            <h2>Cronograma:</h2>
          </div>
          <img
            src={`https://course-dashboard.onrender.com/${cronogramaURL}`}
            style={{ display: 'block', width: '100%' }}
            alt="cronograma"
          />
          <Pricing
            priceClass={priceClass}
            pricesCompleteCourse={pricesCompleteCourse}
            hoursOfClasses={hoursOfClasses}
          />
        </div>
        <div className="col-md-6 sm-12">
          <Form
            isDiscountAvailable={isDiscountAvailable}
            priceClass={priceClass.usd}
            priceClassArg={priceClass.arg}
            pricesCompleteCourse={pricesCompleteCourse}
            listOfClasses={listOfClasses}
          />
        </div>
      </div>
    </div>
  );
};

export default Curso;
