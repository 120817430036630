import ReactDOM from 'react-dom';
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
/* import MessengerCustomerChat from "react-messenger-customer-chat"; */
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

import 'bootstrap/dist/css/bootstrap-grid.min.css';
import './styles/main.scss';

/* COMPONENTS */
import TopBar from './components/TopBar';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
/* CONTAINERS */
import Index from './containers/Index';
import Curso from './containers/Curso';
import GrupoDeEstudio from './containers/GrupoDeEstudio';
import Live from './containers/Live';
/* import Peones from './containers/Peones'; */
import AdminLive from './containers/AdminLive';
import Calendar from './containers/Calendar';
import NotFoundPage from './containers/NotFoundPage';
import MagistralRioGrandeTest from './containers/MagistralRioGrandeTest';
import { AdminCurso } from './containers/AdminCurso';
import { Dashboard } from './containers/Dashboard';
import MayorRosarino from './containers/MayorRosarino';
import PROAM from './containers/PROAM';
import CampeonatoArgentino from './containers/CampeonatoArgentino';
/* import Cipolletti from './containers/Cipolletti'; */

export const routes = [
  { name: 'Inicio', path: '/', component: Index },
  { name: 'Curso Magistral', path: '/curso-magistral', component: Curso },
  { name: 'Campeonato Argentino', path: '/campeonato-argentino', component: CampeonatoArgentino },
/*   {
    name: 'Magistral de Río Grande',
    path: '/magistral-rio-grande',
    component: MagistralRioGrandeTest,
  }, */
/*   { name: 'Mayor Rosarino', path: '/mayor-rosarino', component: MayorRosarino },
  { name: 'PRO-AM', path: '/pro-am', component: PROAM }, */
  /*   {
    name: 'Internacional Río Negro',
    path: '/internacional-rio-negro',
    component: Cipolletti,
  } */ /*  {
    name: 'Grupos de estudio',
    path: '/grupo-de-estudio',
    component: GrupoDeEstudio,
  }, */
  /* { name: 'Peones', path: '/peones', component: Peones }, */
  { name: 'Vivo', path: '/vivo', component: Live },
  { name: 'Calendario', path: '/calendario', component: Calendar },
];

export const App = () => {
  // GOOGLE ANALYTICS
  ReactGA.initialize('UA-133922874-2');
  ReactGA.pageview(window.location.pathname + window.location.search);

  const customHistory = createBrowserHistory();

  return (
    <Router history={customHistory}>
      <header>
        <TopBar />
        <NavBar />
      </header>

      {/* FB MESSENGER */}
      {/*       <MessengerCustomerChat
        pageId="2117440595155500"
        appId="3936569283099905"
        minimized={false}
        shouldShowDialog={true}
        language="es_ES"
      /> */}

      <main>
        <Switch>
          {routes.map(({ path, component }) => (
            <Route exact key={path} path={path} component={component} />
          ))}
          <Route exact path="/vivo/admin" component={AdminLive} />
          <Route exact path="/admin/curso" component={AdminCurso} />
          <Route exact path="/admin/dashboard" component={Dashboard} />
          <Route component={NotFoundPage} />
        </Switch>
      </main>

      <footer>
        <Footer />
      </footer>
    </Router>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
