import React, { useState } from 'react';
import { NavLink, Link, useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';
import { routes } from '../..';

const Navbar = () => {
  const isActive = {
    color: '#007bff',
  };

  const isActiveInMobile = {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  };

  const history = useHistory();
  history.listen((location) =>
    ReactGA.pageview(location.pathname + location.search),
  );

  const [mobileMenuVisibility, setMobileMenuVisibility] = useState('none');

  const handleDisplayNone = () => {
    setMobileMenuVisibility('none');
    document.body.style.overflowY = 'scroll';
  };

  const handleDisplayBlock = () => {
    setMobileMenuVisibility('block');
    document.body.style.overflowY = 'hidden';
  };

  return (
    <>
      <div className="container-fluid navbar">
        <div className="row menu-nowrap">
          <div
            className="col-lg-12 menu ordenador"
            style={{ textAlign: 'center' }}
          >
            {routes.map(({ name, path }) => (
              <NavLink
                exact
                key={path}
                className="menu-link"
                activeStyle={isActive}
                to={path}
              >
                {name}
              </NavLink>
            ))}
            <Link
              to="/curso-magistral"
              style={{ marginLeft: '8%', display: 'inline-block' }}
              className="btn btn-outline"
            >
              Nuestro curso
            </Link>
          </div>
          <div className="col-sm-12 mobile">
            <img
              src="/images/navbar/ham.png"
              onClick={handleDisplayBlock}
              alt="ham icon"
              className="ham-icon"
            />
            <Link
              to="/curso-magistral"
              style={{ marginLeft: '8%', display: 'inline-block' }}
              className="btn btn-outline"
            >
              Nuestro curso
            </Link>
          </div>
        </div>
      </div>

      <div
        className="container-fluid mobile-menu"
        style={{ display: mobileMenuVisibility }}
      >
        <div className="row">
          <div className="col-9"></div>
          <div className="col-3" style={{ marginBottom: '40px' }}>
            <img
              src="/images/navbar/ham-x.png"
              onClick={handleDisplayNone}
              alt="ham icon"
              className="ham-icon ham-icon-x"
            />
          </div>
          <div className="col-12">
            {routes.map(({ name, path }) => (
              <NavLink
                exact
                key={path}
                onClick={handleDisplayNone}
                activeStyle={isActiveInMobile}
                className="menu-link-mobile"
                to={path}
              >
                {name}
              </NavLink>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
