import { useEffect, useRef, useState } from 'react';
import { AdminCalendarImage } from './AdminCalendarImage';

import { AdminClases } from './AdminClases';
import { AdminResetearCurso } from './AdminResetearCurso';

function generateOptions() {
  const arr = [];

  for (let i = 0; i < 101; i++) {
    arr.push(i);
  }

  return arr.map((value) => <option value={value}>{value}</option>);
}

export const AdminCurso = () => {
  const [formKeys, setFormKeys] = useState([]);
  const formRef = useRef(null);

  useEffect(() => {
    if (formRef.current) {
      fetch('https://course-dashboard.onrender.com/curso')
        .then((response) => response.json())
        .then((res) => {
          const formKeys = Object.keys(res?.response).filter(
            (key) =>
              ![
                'id_curso',
                'price_usd_discount',
                'price_arg_discount',
                'calendar_img_url',
              ].includes(key),
          );

          setFormKeys(formKeys);

          if (Array.isArray(formKeys) && formRef?.current) {
            formKeys?.forEach(
              (key) => (formRef.current[key].value = res?.response[key]),
            );
          }
        })
        .catch((err) => console.error(err));
    }
  }, [formRef]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const body = {};

    if (e.target['password'].value !== 'Ajedrez123!') {
      alert('Contraseña incorrecta');
      return;
    }

    formKeys.forEach((key) => (body[key] = e.target[key].value));

    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    };
    fetch('https://course-dashboard.onrender.com/curso', requestOptions)
      .then(() => {
        alert('Actualizado Correctamente');
        formRef.current['password'].value = '';
      })
      .catch((err) => console.log(err));

    console.log(body);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 center">
          <h3>Administrador del curso</h3>
        </div>
        <div className="col-12">
          <form onSubmit={handleSubmit} ref={formRef}>
            <div className="row">
              <div className="col-3">
                <label htmlFor="discount_rate">Porcentaje de descuento</label>
                <br />
                <select name="discount_rate" id="discount_rate">
                  {generateOptions()}
                </select>
              </div>
              <div className="col-3">
                <label htmlFor="limit_date_of_discount">
                  Fecha límite del descuento
                </label>
                <br />
                <input type="date" name="limit_date_of_discount" />
              </div>
              <div className="col-3">
                <label htmlFor="password">Contraseña para actualizar</label>
                <br />
                <input type="password" name="password" />
              </div>
            </div>

            <div className="row">
              <div className="col-3">
                <label htmlFor="price_usd">Precio Curso completo en USD</label>
                <br />
                <input type="text" name="price_usd" />
              </div>
              <div className="col-3">
                <label htmlFor="price_arg">Precio Curso completo en ARG</label>
                <br />
                <input type="text" name="price_arg" />
              </div>
              <div className="col-3">
                <label htmlFor="price_usd_class">Precio Clase en USD</label>
                <br />
                <input type="text" name="price_usd_class" />
              </div>
              <div className="col-3">
                <label htmlFor="price_arg_class">Precio Clase en ARG</label>
                <br />
                <input type="text" name="price_arg_class" />
              </div>
            </div>

            <div className="row">
              <div className="col-3">
                <label htmlFor="title_of_course">Titulo del curso</label>
                <br />
                <input type="text" name="title_of_course" />
              </div>
              <div className="col-3">
                <label htmlFor="date_to_show">
                  Fecha del curso para mostrar
                </label>
                <br />
                <input type="text" name="date_to_show" />
              </div>
              <div className="col-3">
                <label htmlFor="hours_of_classes">Horas de clases</label>
                <br />
                <input type="text" name="hours_of_classes" />
              </div>
              <div className="col-3">
                <label htmlFor="class_duration">Duracion de las clases</label>
                <br />
                <input type="text" name="class_duration" />
              </div>
            </div>
            <div className="row">
              <div className="col-12 center mt-5 mb-5">
                <button type="submit" className="btn btn-blue">
                  Actualizar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <AdminCalendarImage />
      <AdminClases />
      <AdminResetearCurso />
    </div>
  );
};
