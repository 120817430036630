import React, { useState, useEffect } from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { Link } from 'react-router-dom';

const Header = () => {
  const config = [
    {
      key: 0,
      image: 'url("/images/portada1.jpg")',
      superior: 'Curso',
      inferior: 'Magistral',
      subtitle: '¿Te lo vas a perder?',
    },
    {
      key: 1,
      image: 'url("/images/portada2.jpg")',
      superior: 'Ajedrez Latino',
      inferior: 'a la carta',
      subtitle:
        'Experimentados maestros & excelente ambiente para crecer el ajedrez',
    },
  ];

  const LENGTH_SLIDER = config.length - 1;

  const [key, setKey] = useState(config[0].key);
  const [image, setImage] = useState(config[0].image);
  const [superior, setSuperior] = useState(config[0].superior);
  const [inferior, setInferior] = useState(config[0].inferior);
  const [subtitle, setSubtitle] = useState(config[0].subtitle);

  const changeSlider = (key) => {
    let newKey = key;

    newKey === LENGTH_SLIDER ? (newKey = 0) : (newKey = newKey + 1);

    setKey(newKey);
    setImage(config[newKey].image);
    setSuperior(config[newKey].superior);
    setInferior(config[newKey].inferior);
    setSubtitle(config[newKey].subtitle);
  };

  useEffect(() => {
    setInterval(() => {
      changeSlider(key);
    }, 15000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SwitchTransition>
      <CSSTransition
        key={key}
        addEndListener={(node, done) =>
          node.addEventListener('transitionend', done, false)
        }
        classNames="fade"
      >
        <div
          className="container-fluid header"
          style={{ backgroundImage: image }}
        >
          <div className="row header-text">
            <div className="col-12 header-inline">
              <img
                src="/images/logo_blanco.png"
                className="header-logo"
                alt="logo blanco ajedrez latino"
              />
              <div className="vl"></div>
              <div className="header-titulo">
                <h2 className="superior">{superior}</h2>
                <h2 className="inferior">{inferior}</h2>
                <h3 className="subtitle">{subtitle}</h3>
              </div>
            </div>
            <div className="col-12">
              <div className="header-button-container">
                <Link
                  to={key === 0 && '/curso-magistral'}
                  onClick={() =>
                    key === 1 &&
                    window.open(
                      'https://docs.google.com/forms/d/e/1FAIpQLSesm1mVE4-NLBc5wzEvTaaoQ2Er5ZVle83XwIBGpkACuzIRfg/viewform',
                      '_blank',
                    )
                  }
                  className="btn btn-blue btn-block-in-mobile"
                >
                  {key === 0 ? 'Unirme' : 'Unirme'}
                </Link>
                <Link
                  to={key === 0 && '/curso-magistral'}
                  onClick={() =>
                    key === 1 &&
                    window.open(
                      'https://docs.google.com/forms/d/e/1FAIpQLSesm1mVE4-NLBc5wzEvTaaoQ2Er5ZVle83XwIBGpkACuzIRfg/viewform',
                      '_blank',
                    )
                  }
                  style={{ marginLeft: '15px' }}
                  className="btn btn-outline btn-outline-white btn-block-in-mobile"
                >
                  Más Información
                </Link>
              </div>
            </div>
          </div>

          {/*  <img src="/images/portada1.jpg" className="header-portada" alt=""/> */}
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default Header;
