import React, { useEffect, useState } from "react";
import { useFetch } from "./../../hooks/useFetch";

const Live = () => {
  // eslint-disable-next-line no-unused-vars
  const [data, loading, setUrl, reload, setReload] = useFetch(
    "https://ajedrezlatino.com/api/live/list_live.php?token=47566824"
  );

  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [url, setearUrl] = useState("");

  useEffect(() => {
    if (!loading) {
      setDescription(data.description);
      setTitle(data.title);
      setearUrl(data.url);
    }
  }, [loading, data]);

  useEffect(() => {
    const interval = setInterval(() => {
      setReload((reload) => reload + 1);
    }, 12000);
    return () => clearInterval(interval);
  }, [reload, setReload]);

  return loading ? (
    <div className="container">
      <div className="row">
        <div
          className="col-12"
          style={{ textAlign: "center", color: "#007bff" }}
        >
          <h1>Cargando...</h1>
        </div>
      </div>
    </div>
  ) : (
    <div
      className="container"
      style={{
        marginTop: "2%",
        padding: "0",
        paddingRight: "7%",
        paddingLeft: "1%",
      }}
    >
      <div className="row">
        <div className="col-lg-9 sm-12">
          <iframe
            style={{ width: "100%", minHeight: "512px" }}
            src={`https://www.youtube.com/embed/${url}`}
            title={title}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; camera; microphone; fullscreen"
            allowfullscreen
          ></iframe>
        </div>
        <div className="col-lg-3 sm-12">
          <iframe
            style={{ width: "100%", minHeight: "512px" }}
            title="Chat"
            src={`https://www.youtube.com/live_chat?v=${url}&embed_domain=ajedrezlatino.com`}
            frameborder="0"
          ></iframe>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h1 className="live-title">{title}</h1>
          <p className="live-description">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default Live;
