import Swal from "sweetalert2";
export const loadingRequest = () => {
  Swal.fire({
    title: "Espere por favor ...",
    onBeforeOpen() {
      Swal.showLoading();
    },
    onAfterClose() {
      Swal.hideLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    showConfirmButton: false,
  });
};

export const successRequest = (
  message = "Operación realizada correctamente"
) => {
  Swal.fire("Correcto!", message, "success");
};

export const errorRequest = (
  message = "Ha ocurrido un error, intente más tarde!"
) => {
  Swal.fire("Error!", message, "error");
};

export const updateLive = ({ description, title, url, password }, event) => {
  loadingRequest();

  return new Promise((resolve, reject) => {
    let formData = new FormData();

    formData.append("description", description);
    formData.append("title", title);
    formData.append("url", url);
    formData.append("password", password);

    // TODO: move url and token to .env

    fetch("https://ajedrezlatino.com/api/live/update_live.php?token=47566824", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .catch((error) => {
        errorRequest();
        reject(false);
      })
      .then(({ result, message }) => {
        if (result) {
          successRequest(message);
          event.target.reset();
          resolve(true);
        } else {
          errorRequest();
          // error
        }
      });
  });
};
