import React from 'react';

const Pricing = ({ pricesCompleteCourse, priceClass, hoursOfClasses }) => {
  return (
    <section className="pricing">
      <div className="col-12 heading">
        <h2>Precio:</h2>
      </div>

      <div className="pricing-subtitle">
        <span>
          Incluye {hoursOfClasses} de clase en el grupo único
          {/* seleccionado (Sub 1800 o Supra 1800) */}
          <br />+ la grabación de las mismas
        </span>
      </div>

      <div className="pricing-metodos-de-pago">
        <h4>Métodos de pago</h4>
        <ul>
          <li>
            Paypal, tarjeta débito o crédito internacional{' '}
            <span className="precio-tachado">
              USD {pricesCompleteCourse.withOutDiscount.usd}
            </span>{' '}
            USD {pricesCompleteCourse.withDiscount.usd}
          </li>
          <li>
            Transferencia bancaria solo Argentinos{' '}
            <span className="precio-tachado">
              $AR {pricesCompleteCourse.withOutDiscount.arg}
            </span>{' '}
            $AR {pricesCompleteCourse.withDiscount.arg}
          </li>
          <li>
            Mercado Pago Argentinos{' '}
            <span className="precio-tachado">
              $AR {pricesCompleteCourse.withOutDiscount.arg}
            </span>{' '}
            $AR {pricesCompleteCourse.withDiscount.arg}
          </li>
        </ul>
        <h4>Precio por clase (No tienen 10% de bonificación)</h4>
        <ul>
          <li>
            Paypal, tarjeta débito o crédito internacional USD {priceClass.usd}
          </li>
          <li>Transferencia bancaria solo Argentinos $AR {priceClass.arg}</li>
          <li>Mercado Pago Argentinos $AR {priceClass.arg}</li>
        </ul>
      </div>

      <hr className="hr" />
    </section>
  );
};

export default Pricing;
