import React from "react";
import {
  LogoFacebook,
  LogoYoutube,
  LogoInstagram,
  LogoTwitter,
  LogoWhatsapp,
  /*   Person,
  Search, */
} from "react-ionicons";

const TopBar = () => {
  const reedirect = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="container topbar">
      <div className="row">
        <div className="col-lg-4 col-sm-12">
          <LogoFacebook
            className="topbar-icon"
            style={{ transform: "translateY(15px)" }}
            color={"#00000"}
            height="25px"
            width="25px"
            onClick={() => reedirect("https://facebook.com/ajedrezlatino")}
          />
          <LogoYoutube
            className="topbar-icon"
            style={{ transform: "translateY(15px)" }}
            color={"#00000"}
            height="25px"
            width="25px"
            onClick={() => reedirect("https://youtube.com/ajedrezlatino")}
          />
          <LogoInstagram
            className="topbar-icon"
            style={{ transform: "translateY(15px)" }}
            color={"#00000"}
            height="25px"
            width="25px"
            onClick={() => reedirect("https://instagram.com/ajedrezlatino")}
          />
          <LogoTwitter
            className="topbar-icon"
            style={{ transform: "translateY(15px)" }}
            color={"#00000"}
            height="25px"
            width="25px"
            onClick={() => reedirect("https://twitter.com/latinoajedrez")}
          />
          <LogoWhatsapp
            className="topbar-icon"
            style={{ transform: "translateY(15px)" }}
            color={"#00000"}
            height="25px"
            width="25px"
            onClick={() =>
              reedirect(
                "https://api.whatsapp.com/send/?phone=59897371782&text&app_absent=0"
              )
            }
          />
        </div>
        <div className="col-lg-4 col-sm-12">
          <img
            src="/images/logo.png"
            className="topbar-logo"
            alt="logo ajedrez latino"
          />
        </div>
        <div className="col-lg-4 col-sm-12">
          {/*           <Person
            className="topbar-icon"
            style={{ transform: "translateY(15px)" }}
            color={"#00000"}
            height="25px"
            width="25px"
          />
          <Search
            className="topbar-icon"
            style={{ transform: "translateY(15px)" }}
            color={"#00000"}
            height="25px"
            width="25px"
          /> */}
        </div>
      </div>
    </div>
  );
};

export default TopBar;
