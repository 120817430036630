import React, { useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import Swal from 'sweetalert2';

import PaypalButtonDiv from './PaypalButtonDiv';
import MercadoPagoButton from './MercadoPagoButton';
import PaypalButtonDivClase from './PaypalButtonDivClase';

// import onSubmit from '../../helper/handleSubmitPayment';

const Form = ({
  isDiscountAvailable,
  priceClass,
  priceClassArg,
  pricesCompleteCourse,
  listOfClasses,
}) => {
  const [chooseClass, setChooseClass] = useState(false);
  const [metodoDePago, setMetodoDePago] = useState(null);
  const [plan, setPlan] = useState(null);

  const { register, handleSubmit, formState, watch } = useForm();

  function onSubmit(data, e) {
    e.preventDefault();
    if (localStorage.getItem('click') !== '1') {
      return false;
    }

    if (data.metodoPago === 'paypal') {
      if (parseInt(localStorage.getItem('payment_approved')) !== 1) {
        Swal.fire(
          'Error!',
          'Debe completar el pago a través de Paypal antes de poder continuar',
          'error',
        );
        return false;
      }
    }

    const newData = {
      ...data,
      price_course_usd:
        pricesCompleteCourse[
          isDiscountAvailable ? 'withDiscount' : 'withOutDiscount'
        ].usd,
      price_course_arg:
        pricesCompleteCourse[
          isDiscountAvailable ? 'withDiscount' : 'withOutDiscount'
        ].arg,
      price_class_usd: priceClass,
      price_class_arg: priceClassArg,
    };

    Swal.fire({
      title: 'Registrando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const url = 'https://ajedrezlatino.com/curso-febrero/api/index.php';

    try {
      localStorage.removeItem('click');

      axios({
        method: 'POST',
        url: url,
        data: JSON.stringify(newData),
      })
        .then(function (res) {
          if (res.data.result) {
            Swal.fire({
              title: 'Correcto!',
              allowEscapeKey: false,
              allowOutsideClick: false,
              text: res.data.message,
              icon: 'success',
            });
            localStorage.removeItem('payment_approved');
            e.target.reset();
          } else {
            Swal.fire(
              'Error!',
              'Ha ocurrido un error inesperado!, pruebe más tarde',
              'error',
            );
          }
        })
        .catch((err) => {
          Swal.fire(
            'Error!',
            'Ha ocurrido un error inesperado!, pruebe más tarde',
            'error',
          );
        });
    } catch (err) {
      localStorage.removeItem('click');
      Swal.fire(
        'Error!',
        'Ha ocurrido un error inesperado!, pruebe más tarde',
        'error',
      );
    }
  }

  const errors = formState.errors;
  const sessionId = useMemo(() => Date.now(), []);

  const data = {
    session_id: sessionId,
    name: watch('name'),
    celular: watch('celular'),
    email: watch('email'),
    pais: watch('pais'),
    lichess: watch('lichess'),
    metodoPago: metodoDePago,
    plan: plan,
  };

  if (formState.isSubmitSuccessful && metodoDePago != null) {
    setMetodoDePago(null);
    setChooseClass(false);
    setPlan(null);
  }

  return (
    <section className="form row">
      <div className="col-12 heading center">
        <h2>Inscribirme:</h2>
      </div>

      <form className="col-lg-12 sm-12" onSubmit={handleSubmit(onSubmit)}>
        <h2>Inscribirme:</h2>
        <label>Nombre Completo:</label>
        <input
          type="text"
          placeholder="Nombre completo"
          name="name"
          ref={register({
            required: 'Ingrese su nombre completo',
            maxLength: 180,
          })}
        />
        <div className="error-input">{errors.name && errors.name.message}</div>
        <label>Email:</label>
        <input
          type="text"
          name="email"
          ref={register({
            /* eslint-disable-next-line no-useless-escape */
            pattern:
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            required: 'Ingrese un email válido',
          })}
        />
        <div className="error-input">
          {errors.email && errors.email.message}
        </div>
        <label>Usuario de lichess.org:</label>
        <input
          type="text"
          placeholder="Usuario de lichess.org"
          name="lichess"
          ref={register({ required: 'Ingrese un Usuario de lichess.org' })}
        />
        <div className="error-input">
          {errors.lichess && errors.lichess.message}
        </div>
        <label>Celular:</label>
        <input
          type="number"
          placeholder="Celular"
          name="celular"
          ref={register({ required: 'Ingrese un celular de contacto' })}
        />
        <div className="error-input">
          {errors.celular && errors.celular.message}
        </div>
        <label>País:</label>
        <input
          type="text"
          placeholder="País"
          name="pais"
          ref={register({ required: 'Ingrese su país de residencia' })}
        />
        <div className="error-input">{errors.pais && errors.pais.message}</div>
        <label>Elo FIDE:(opcional)</label>
        <input type="number" name="eloFide" ref={register()} />
        {/*      <label>Grupo:</label>  */}
        <input
          name="grupo"
          type="hidden"
          value="sub1800"
          ref={register()}
        />{' '}
        {/* Sub 1800
      <input name="grupo" type="radio" value="supra1800" ref={register({ required: "Seleccione un grupo" })}/>Supra 1800<br/>
      <div className="error-input">{errors.grupo && errors.grupo.message}</div>  */}
        <label>Plan:</label>
        <input
          name="plan"
          onClick={(e) => {
            setChooseClass(false);
            setPlan(e.target.value);
          }}
          type="radio"
          value="completo"
          ref={register({ required: 'Seleccione un plan' })}
        />
        Curso completo
        <input
          name="plan"
          onClick={(e) => {
            setChooseClass(true);
            setPlan(e.target.value);
          }}
          type="radio"
          value="clase"
          ref={register({ required: 'Seleccione un plan' })}
        />
        Una clase
        <br />
        <div className="error-input">{errors.plan && errors.plan.message}</div>
        <label style={{ display: chooseClass ? 'block' : 'none' }}>
          Elija la clase:
        </label>
        <select
          name="clase"
          style={{ display: chooseClass ? 'block' : 'none' }}
          ref={register({ required: true })}
        >
          {listOfClasses.map((clase) => (
            <option key={clase} value={clase}>
              {clase}
            </option>
          ))}
        </select>
        <label>Método de pago:</label>
        <input
          name="metodoPago"
          onClick={(e) => setMetodoDePago(e.target.value)}
          type="radio"
          value="paypal"
          ref={register({ required: 'Seleccione un método de pago' })}
        />
        Paypal
        <br />
        <input
          name="metodoPago"
          onClick={(e) => setMetodoDePago(e.target.value)}
          type="radio"
          value="transferenciaBancaria"
          ref={register({ required: 'Seleccione un método de pago' })}
        />
        Transferencia bancaria
        <br />
        <input
          name="metodoPago"
          onClick={(e) => setMetodoDePago(e.target.value)}
          type="radio"
          value="mercadoPago"
          ref={register({ required: 'Seleccione un método de pago' })}
        />
        Mercado Pago ARG
        <br />
        <div className="error-input">
          {errors.metodoPago && errors.metodoPago.message}
        </div>
        {metodoDePago === 'mercadoPago' &&
          (plan === 'completo' || plan === 'clase') && (
            <span
              style={{
                fontFamily: 'Helvetica Neue", Arial, sans-serif',
                background: '#009ee3',
                color: '#fff',
                cursor: 'pointer',
                fontSize: '0.875em',
                padding: '0.5em 1.7em 0.5em 1.7em',
                borderRadius: '0.3em',
              }}
              onClick={() =>
                window.document
                  .getElementsByClassName('mercadopago-button')[0]
                  .click()
              }
            >
              Pagar en Mercado Pago
            </span>
          )}
        {metodoDePago === 'paypal' && plan === 'completo' ? (
          <PaypalButtonDiv
            pricesCompleteCourse={pricesCompleteCourse}
            isDiscountAvailable={isDiscountAvailable}
          />
        ) : (
          ''
        )}
        {metodoDePago === 'paypal' && plan === 'clase' ? (
          <PaypalButtonDivClase priceClass={priceClass} />
        ) : (
          ''
        )}
        {metodoDePago === 'transferenciaBancaria' ? (
          <b className="transferencia-bancaria">
            Los datos para la transferencia le serán enviados por mail.
          </b>
        ) : (
          ''
        )}
        <button
          onClick={() => {
            localStorage.setItem('click', 1);
          }}
          className="btn btn-blue btn-block"
          type="submit"
        >
          Registrarme
        </button>
      </form>
      {metodoDePago === 'mercadoPago' && plan === 'completo' && (
        <MercadoPagoButton data={data} plan={plan} />
      )}
      {metodoDePago === 'mercadoPago' && plan === 'clase' && (
        <MercadoPagoButton data={data} plan={plan} />
      )}
    </section>
  );
};

export default Form;
