import React from 'react';
import { Link } from 'react-router-dom';

const GruposDeEstudio = () => {
  return (
    <div className="container-fluid gruposDeEstudio">
      <div style={{ height: '70px' }}></div>
      <div className="container">
        <div className="row">
          <div
            className="col-lg-6 sm-12"
            style={{ marginTop: 'auto!important' }}
          >
            <h4>- Mejora tu ajedrez</h4>
            <h2>Ajedrez Latino a la carta</h2>
            <p>
              El principal beneficio de estudiar en grupo es que aprenderás
              distintas formas de entender un tema. Cuando estudias solo tienes
              una visión, en cambio al hacerlo en compañía enriquecerás tu
              conocimiento y lograrás comprender de forma global los conceptos
              acompañado de la más alta calidad de profesores experimentados que
              ofrece ajedrez latino.
            </p>
            <p>
              Recordá que el precio incluye cuatro clases de una hora y media,
              todas quedan grabadas, se entrega material complementario y hay
              una actividad práctica después de la última clase del mes
            </p>
            <div className="header-button-container">
              <button
                onClick={() =>
                  window.open(
                    'https://docs.google.com/forms/d/e/1FAIpQLSesm1mVE4-NLBc5wzEvTaaoQ2Er5ZVle83XwIBGpkACuzIRfg/viewform',
                    '_blank',
                  )
                }
                className="btn btn-blue"
              >
                Unirme
              </button>
              <button
                onClick={() =>
                  window.open(
                    'https://docs.google.com/forms/d/e/1FAIpQLSesm1mVE4-NLBc5wzEvTaaoQ2Er5ZVle83XwIBGpkACuzIRfg/viewform',
                    '_blank',
                  )
                }
                style={{ marginLeft: '15px' }}
                className="btn btn-outline btn-outline-white"
              >
                Más Información
              </button>
            </div>
          </div>
          <div
            className="col-lg-6 sm-12"
            style={{ marginTop: 'auto', marginBottom: 'auto' }}
          >
            <div className="row">
              <div className="col-lg-6 sm-12 card card1">
                <h5>Argentinos</h5>
                <h5 style={{ color: '#007bff' }}>$8000</h5>
                <hr />
                <h5>Extranjeros</h5>
                <h5 style={{ color: '#007bff' }}>U$D40</h5>
              </div>
              <div className="col-lg-6 sm-12 card card2">
                <h6>Temas nuevos siempre</h6>
                <hr />
                <h6>Varios días y horarios</h6>
                <hr />
                <h6>Varios métodos de pago</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: '70px' }}></div>
    </div>
  );
};

export default GruposDeEstudio;
