import React from "react";
import NotFoundImage from "./404.png";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12" style={{ textAlign: "center" }}>
          <Link to="/">
            <h1>{`<- `}Volver al Inicio</h1>
          </Link>
          <img
            style={{ width: "100%", display: "block" }}
            src={NotFoundImage}
            alt="no found"
          />
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
