import { useRef } from 'react';

export const AdminCalendarImage = () => {
  const imageRef = useRef();
  const formRef = useRef(null);

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('calendar', imageRef?.current?.files[0]);

    if (e.target['password'].value !== 'Ajedrez123!') {
      alert('Contraseña incorrecta');
      return;
    }

    const res = await fetch(
      'https://course-dashboard.onrender.com/course-image',
      {
        method: 'post',
        body: formData,
      },
    )
      .then((res) => res.json())
      .catch((err) => console.log('error >> ', err));

    if (res?.response?.calendar_img_url) {
      alert('Actualizado Correctamente');
      formRef.current['password'].value = '';
      formRef.current['calendar'].value = '';
    }
  };

  return (
    <div className="row">
      <div className="col-sm-12 col-md-4"></div>
      <div className="col-sm-12 col-md-4">
        <h3>Subir imagen de calendario</h3>
        <form ref={formRef} onSubmit={onSubmit}>
          <div className="col-12">
            <label htmlFor="password">Contraseña para actualizar</label>
            <br />
            <input type="password" name="password" />
          </div>
          <div className="col-12">
            <label htmlFor="calendar">Archivo de imagen</label>
            <br />
            <input ref={imageRef} type="file" name="calendar" />
          </div>
          <div className="row">
            <div className="col-12 center mt-5 mb-5">
              <button type="submit" className="btn btn-blue">
                Actualizar
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="col-sm-12 col-md-4"></div>
    </div>
  );
};
