import React from "react";
import {
  LogoFacebook,
  LogoYoutube,
  LogoInstagram,
  LogoTwitter,
  LogoWhatsapp,
  /*   MailOutline, */
} from "react-ionicons";

const TopBar = () => {
  const reedirect = (url) => {
    window.open(url, "_blank");
  };
  const year = new Date().getFullYear();
  return (
    <div className="container-fluid footer">
      <div style={{ height: "50px" }}></div>
      <div className="container footer-top">
        <div className="row">
          <div className="col-lg-4 col-sm-12">
            <img src="/images/logo.png" alt="logo ajedrez latino" />
          </div>
          <div className="col-lg-4 col-sm-12"></div>
          <div className="col-lg-4 col-sm-12">
            <LogoFacebook
              className="footer-icon"
              style={{ transform: "translateY(15px)" }}
              color={"#ffffff"}
              height="25px"
              width="25px"
              onClick={() => reedirect("https://facebook.com/ajedrezlatino")}
            />
            <LogoYoutube
              className="footer-icon"
              style={{ transform: "translateY(15px)" }}
              color={"#ffffff"}
              height="25px"
              width="25px"
              onClick={() => reedirect("https://youtube.com/ajedrezlatino")}
            />
            <LogoInstagram
              className="footer-icon"
              style={{ transform: "translateY(15px)" }}
              color={"#ffffff"}
              height="25px"
              width="25px"
              onClick={() => reedirect("https://instagram.com/ajedrezlatino")}
            />
            <LogoTwitter
              className="footer-icon"
              style={{ transform: "translateY(15px)" }}
              color={"#ffffff"}
              height="25px"
              width="25px"
              onClick={() => reedirect("https://twitter.com/latinoajedrez")}
            />
            <LogoWhatsapp
              className="footer-icon"
              style={{ transform: "translateY(15px)" }}
              color={"#ffffff"}
              height="25px"
              width="25px"
              onClick={() =>
                reedirect(
                  "https://api.whatsapp.com/send/?phone=59897371782&text&app_absent=0"
                )
              }
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="contaier">
        <div className="row">
          <div className="col-12">
            {/*             <div className="magic-input">
                <div className="w-70">
                  <MailOutline
                    style={{ transform: "translateY(6px)", marginLeft: "10px" }}
                    color={"#ffffff"}
                    height="25px"
                    width="25px"
                  />
                  <input
                    name="email"
                    className="css-input "
                    type="email"
                    placeholder="E-mail"
                  />
                </div>
                <div className="w-30">
                  <button>
                    <span>Suscribirse</span>
                  </button>
                </div>
            </div> */}
          </div>
          <div className="col-12">
            <p className="copyright">
              © Copyright {year} - Todos Los Derechos Reservados.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
