import React, { useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import campeonatoArgentinoIMG from './campeonato-argentino.jpeg';

import hungaIMG from './hunga.jpeg';
import fadaIMG from './fada.jpeg';
import fideIMG from './fide.jpeg';
import rioNegroIMG from './rn.jpeg';
import melaIMG from './mela.jpeg';

import { useFetch } from '../../hooks/useFetch';
import './style.scss';

const CampeonatoArgentino = () => {
  const [data, loading, setUrl, reload, setReload] = useFetch(
    'https://ajedrezlatino.com/api/live/list_live.php?token=475668244',
  );

  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('');
  const [url, setearUrl] = useState('');
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    if (!loading) {
      setDescription(data.description);
      setTitle(data.title);
      setearUrl(data.url);
    }
  }, [loading, data]);

  useEffect(() => {
    const interval = setInterval(() => {
      setReload((reload) => reload + 1);
    }, 12000);
    return () => clearInterval(interval);
  }, [reload, setReload]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12" style={{ textAlign: 'center' }}>
          <h1>97° CAMPEONATO ARGENTINO DE AJEDREZ</h1>
          {/*           <h3>
            100 años FADA con 50.000 U$D en premios garantizados
            <br />
            dará inicio martes 11 de octubre y finalizará el martes 18 de
            octubre de 2022
          </h3> */}
          <h2>⬇️⬇️ Camara en vivo desde el torneo ⬇️⬇️</h2>
          <div className="row">
            <div className="col-lg-9 sm-12">
              <iframe
                style={{ width: '100%', minHeight: '512px' }}
                src={`https://www.youtube.com/embed/Wqhgip8E9ck4`}
                title={title}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; camera; microphone; fullscreen"
                allowfullscreen
              ></iframe>
            </div>
            <div className="col-lg-3 sm-12">
              <iframe
                style={{ width: '100%', minHeight: '512px' }}
                title="Chat"
                src={`https://www.youtube.com/live_chat?v=${url}&embed_domain=ajedrezlatino.com`}
                frameborder="0"
              ></iframe>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-4 sm-12"></div>
            <div className="col-md-4 sm-12" style={{ textAlign: 'center' }}>
              <h1>
                <button
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-lichess btn-block"
                  onClick={() =>
                    window.open(
                      'https://federacionargentinadeajedrez.org/?p=5177',
                      '_blank',
                    )
                  }
                >
                  Ver reglamento
                </button>
              </h1>
            </div>
            <div className="col-md-4 sm-12"></div>
          </div>
          <div className="row">
            <div className="col-md-4 sm-12"></div>
            <div className="col-md-4 sm-12" style={{ textAlign: 'center' }}>
              <h1>
                <button
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-blue btn-block"
                  onClick={() =>
                    window.open(
                      'http://chess-results.com/tnr675251.aspx?lan=2&zeilen=99999',
                      '_blank',
                    )
                  }
                >
                  Chess Results
                </button>
              </h1>
            </div>
            <div className="col-md-4 sm-12"></div>
          </div>
          <div className="row">
            <div className="col-md-4 sm-12"></div>
            <div className="col-md-4 sm-12" style={{ textAlign: 'center' }}>
              <h1>
                <button
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-youtube btn-block"
                  onClick={() =>
                    window.open(
                      'https://www.youtube.com/playlist?list=PLTyBcQMz8jxkh1GaX9fWv1SjVoaidPkma',
                      '_blank',
                    )
                  }
                >
                  Ver resumenes de las rondas en Youtube
                </button>
              </h1>
            </div>
            <div className="col-md-4 sm-12"></div>
          </div>
          <div className="row">
            <div className="col-md-4 sm-12"></div>
            <div className="col-md-4 sm-12" style={{ textAlign: 'center' }}>
              <h1>
                <button
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-blue btn-block"
                  onClick={() =>
                    window.open(
                      'https://docs.google.com/forms/d/e/1FAIpQLSdJItQhBrhFWUDZICYYY15vRpDNiVn_A0CUGnbnj7Xmg-NOzw/viewform',
                      '_blank',
                    )
                  }
                >
                  Inscribirme Aquí
                </button>
              </h1>
            </div> 
            <div className="col-md-4 sm-12"></div>
          </div>
          <div className="row">
            <div className="col-md-4 sm-12"></div>
            <div className="col-md-4 sm-12" style={{ textAlign: 'center' }}>
              <h1>
                <button
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-lichess btn-block"
                  onClick={() =>
                    window.open(
                      'https://lichess.org/broadcast/abierto-internacional-proam-100-anos-fada--pro-am-international-chess-tournamen/round-1/10trAdgy',
                      '_blank',
                    )
                  }
                >
                  Ver transmisión en vivo por Lichess
                </button>
              </h1>
            </div>
            <div className="col-md-4 sm-12"></div>
          </div>*/}
          <div className="row">
            <div className="col-md-4 sm-12"></div>
            <div className="col-md-4 sm-12" style={{ textAlign: 'center' }}>
              <h1>
                <button
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-blue btn-block"
                  onClick={() =>
                    window.open(
                      'https://chess-results.com/tnr698128.aspx?lan=2&art=2&turdet=YES',
                      '_blank',
                    )
                  }
                >
                  Chess Results
                </button>
              </h1>
            </div>
            <div className="col-md-4 sm-12"></div>
          </div>
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-md-12 col-lg-8">
              <img
                style={{
                  width: '100%',
                  marginBottom: '16px',
                  marginTop: '16px',
                }}
                src={campeonatoArgentinoIMG}
                alt="ara"
              />
            </div>
            <div className="col-lg-2"></div>
          </div>
          <div className="row">
            <div className="col-12" style={{ textAlign: 'center' }}>
              {' '}
              <h1>APOYAN</h1>
            </div>
            <div
              style={{ marginTop: 'auto', marginBottom: 'auto' }}
              className="col-md-1 sm-6"
            ></div>
            <div
              style={{ marginTop: 'auto', marginBottom: 'auto' }}
              className="col-md-2 sm-6"
            >
              <img style={{ width: '100%' }} src={hungaIMG} alt="carg" />
            </div>
            <div
              style={{ marginTop: 'auto', marginBottom: 'auto' }}
              className="col-md-2 sm-6"
            >
              <img style={{ width: '100%' }} src={fadaIMG} alt="fada" />
            </div>
            <div
              style={{ marginTop: 'auto', marginBottom: 'auto' }}
              className="col-md-2 sm-6"
            >
              <img style={{ width: '100%' }} src={fideIMG} alt="fide" />
            </div>
            <div
              style={{ marginTop: 'auto', marginBottom: 'auto' }}
              v
              className="col-md-2 sm-6"
            >
              <img style={{ width: '80%' }} src={rioNegroIMG} alt="carg" />
            </div>
            <div
              className="col-md-2 sm-6"
              style={{ marginBottom: 'auto', marginTop: 'auto' }}
            >
              <img style={{ width: '100%' }} src={melaIMG} alt="carg" />
            </div>
            <div className="col-12" style={{ textAlign: 'center' }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampeonatoArgentino;
