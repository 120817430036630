export const Dashboard = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(e);

    const data = new FormData(e.target);

    fetch('http://localhost:3001/video', {
      method: 'POST',
      body: data,
    })
      .then((res) => res.json())
      .then((json) => console.log(json))
      .catch((err) => console.log(err));
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 center">
          <h2>Subir video de curso</h2>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-3">
                <label htmlFor="name">Nombre del video</label>
                <br />
                <input name="name" id="name" />
              </div>
              <div className="col-3">
                <label htmlFor="profesor">Profesor</label>
                <br />
                <input name="profesor" id="profesor" />
              </div>
              <div className="col-3">
                <label htmlFor="price">Price</label>
                <br />
                <input name="price" id="price" />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <label htmlFor="file">Archivo</label>
                <br />
                <input type="file" name="file" id="file" />
              </div>
              <div className="col-12">
                <button type="submit">Subir</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
